// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:9e7d74fa-8942-49eb-b433-51b44e17a864",
    "aws_cognito_region": "us-west-2",
    "oauth": {},
    "aws_mobile_analytics_app_id": "ed573888adb144609ce63c98d349985a",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default awsmobile;
