import Amplify, { Analytics } from 'aws-amplify';
import config from './src/aws-exports';

export const onRouteUpdate = ({ location }) => {
  if (process.env.GATSBY_ENV === 'production') {
    Amplify.configure(config);
    Analytics.record({
      name: 'pageView',
      attributes: { path: location.pathname },
    });
  }
};
