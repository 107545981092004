// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-music-page-js": () => import("./../src/templates/music-page.js" /* webpackChunkName: "component---src-templates-music-page-js" */),
  "component---src-templates-shows-page-js": () => import("./../src/templates/shows-page.js" /* webpackChunkName: "component---src-templates-shows-page-js" */),
  "component---src-templates-release-post-js": () => import("./../src/templates/release-post.js" /* webpackChunkName: "component---src-templates-release-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

